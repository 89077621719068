<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='路由'>
            <a-input placeholder="RouteKey" v-model="searchForm.RouteKey"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='路由'>
            <a-input placeholder="RouteKey" v-model="searchForm.RouteKey"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='队列'>
            <a-input placeholder="QueueName" v-model="searchForm.QueueName"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='交换器'>
            <a-input placeholder="Exchange" v-model="searchForm.Exchange"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          :style="{ display: expand ? 'block' : 'none' }"
        >
          <a-form-item label='错误描述'>
            <a-input placeholder="Error" v-model="searchForm.Error"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          :style="{ display: expand ? 'block' : 'none' }"
        >
          <a-form-item label='生成时间'>
            <a-range-picker v-model="searchForm.CreatedAt" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick">
            查询
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="expand = !expand">
            {{expand ? '收起' : '展开'}} <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    
    
    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-col>
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="column-height" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'default' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'default'">默认</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'middle' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'middle'">中等</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'small' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'small'">紧凑</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="() => {screenfull.toggle();isFullscreen=!isFullscreen}"/>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" icon="reload" @click="searchClick" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group :value="showColumns" @change="changeColumns">
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{it}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="setting" />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="record => record.uuid"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 1300 }"
        @change="handleTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
      </a-table>
    </div>
    <!-- table end -->
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import screenfull from 'screenfull'
import { Customer } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'
const INIT_SEARCH_FORM = '{"RouteKey":"","QueueName":"","Exchange":"","Error":"","CreatedAt":[]}'
const columns = [
  {
    title: 'Uuid',
    width: 150,
    dataIndex: 'uuid',
  },
  {
    title: '交换器',
    width: 150,
    dataIndex: 'exchange',
  },
  {
    title: '路由',
    width: 150,
    dataIndex: 'routeKey',
  },
  {
    title: '队列',
    width: 150,
    dataIndex: 'queueName',
  },
  {
    title: '错误描述',
    width: 150,
    dataIndex: 'error',
  },
  {
    title: '时间',
    width: 150,
    dataIndex: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 130,
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  }
]
export default {
  name: 'MQNoAck',
  mixins: [aliveVM, mixinTableList],
  created () {
    const baseColumns = []
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    this.$set(this, 'baseColumns', baseColumns)
    this.fetch()
  },
  computed: {
    showColumns() {
      let c = []
      for (const it of this.columns) {
        c.push(it.title)
      }
      return c
    }
  },
  data () {
    return {
      screenfull,
      isFullscreen: false,
      expand: false,
      tableListApi: `${Customer}/NoAckTableList`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      baseColumns: [],
      columns,
      // 其他
      moment,
      showMQMessage: false,
      MQMessageContent: '',
      MKOptions: {
        markdownIt: {
          linkify: true
        },
        linkAttributes: {
          attrs: {
            target: '_blank',
            rel: 'noopener'
          }
        }
      }
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', null)
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', null)
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    // other
  }
}
</script>

<style>

</style>